@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&display=swap');

.note-1 {
    position: fixed;
    padding: 1.5rem;

    border-radius: 10px;
    height: 45%;
    width: 50%;
    color: black;
    display: flex;
    font-family: "Merienda", cursive;
    font-weight: 400;
    font-size: 2vh;
    white-space: pre-wrap;
    overflow: scroll;
    flex-direction: column;


    transform-origin: top;
}

.note-2 {
    position: fixed;
    padding: 1.5rem;

    border-radius: 10px;
    height: 45%;
    width: 50%;
    color: black;
    display: flex;
    font-family: "Merienda", cursive;
    font-weight: 400;
    font-size: 2vh;
    overflow: scroll;
    white-space: pre-wrap;
    flex-direction: column;

    transform-origin: top;
}

.note-header {
    text-align: left;
    padding-top: 3vh;
}

.note-hook-1 {
    position: relative;
    bottom: 23%;
    right: 20%;

    height: 4vh;
    width: 1vw;
    background-color: #c2c2c2;
    z-index: 5;
}

.note-hook-2 {
    position: relative;
    bottom: 23%;
    left: 20%;

    height: 4vh;
    width: 1vw;
    background-color: #c2c2c2;
    z-index: 5;
}